/* Estilos generales */
body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #ff9a9e, #fad0c4);
  margin: 0;
  font-family: 'Arial', sans-serif;
}

/* Contenedor principal */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1500px;
  position: relative;
}

/* Sobre */
.envelope {
  width: 320px;
  height: 220px;
  position: relative;
  background: #ffccd5;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.5s ease-in-out, box-shadow 0.3s;
}

.envelope:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
}

.envelope.open {
  transform: translateY(-30px);
}

/* Tapa del sobre */
.flap {
  position: absolute;
  width: 100%;
  height: 110px;
  background: #ff99a5;
  top: 0;
  left: 0;
  border-radius: 15px 15px 0 0;
  transform-origin: top;
  transition: transform 0.6s ease-in-out;
}

.envelope.open .flap {
  transform: rotateX(180deg);
}

/* Texto del sobre */
.front-text {
  position: absolute;
  top: 40px;
  font-size: 20px;
  font-weight: bold;
  color: #a00;
  text-align: center;
  width: 100%;
  animation: pulse 1.5s infinite alternate;
}

/* Carta */
.letter {
  position: absolute;
  width: 90%;
  height: 90%;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
  overflow-y: auto;
  max-height: 300px;
  transition: transform 0.6s ease-in-out;
}

.envelope.open .letter {
  transform: translateY(0);
}

/* Corazones flotantes */
.floating-hearts {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.floating-hearts::before,
.floating-hearts::after {
  content: "❤️";
  font-size: 2rem;
  position: absolute;
  opacity: 0;
  animation: floatUp 4s infinite ease-in-out;
}

.floating-hearts::before {
  left: 20%;
  animation-delay: 1s;
}

.floating-hearts::after {
  right: 20%;
  animation-delay: 2s;
}

/* Animaciones */
@keyframes floatUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(-200px);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

/* Diseño responsive para móviles */
@media (max-width: 500px) {
  .envelope {
    width: 260px;
    height: 280px;
  }

  .flap {
    height: 90px;
  }

  .front-text {
    font-size: 18px;
    top: 30px;
  }

  .letter {
    max-height: 250px;
    padding: 15px;
  }
}
